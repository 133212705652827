.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#main {
  background-color: black;
  padding: 50px 0 50px 0;
}

.main {
  display: flex;
  justify-content: space-between;
}

.main-text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.logo-test {
  color: #909090;
}

.logo-test p {
  margin-left: 10px;
}
.main-image,
.main-image1 {
  width: 50%;
  height: 800px;
  position: relative;
  display: flex;
}
.txt {
  position: absolute;
  background-color: #909090;
  width: 150px;
  height: 150px;
  margin-top: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  right: 20px;
  top: 20px;
  font-weight: 600;
  font-size: 12px;
  padding: 0 10px;
}
.bio {
  margin-top: 60px;
  font-size: 60px;
  color: #af1c1c;
  line-height: 1;
}
.txt1 {
  margin-left: 10px;
  font-size: 60px;
}
.learnmoretxt button {
  font-weight: 700;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
  color: #909090;
  padding: 15px;
  width: 180px;
  background-color: black;
}
.signup button {
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 15px;
  width: 180px;
  background-color: #909090;
}

#lean {
  background-color: #af1c1c;
  padding: 50px 0 50px 0;
}

#amenities {
  background-color: black;
  padding: 50px 0 50px 0;
}
.amenities-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.amenities-text {
  display: flex;
  font-size: 50px;
  color: #af1c1c;
  justify-content: flex-end;
  text-align: end;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#footer {
  background-color: #af1c1c;
  padding: 50px;
  padding-bottom: 150px;
}

.dataContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.card1 {
  width: 23%;
  margin-right: 10px;
}
.footer-logo {
  display: flex;
}

@media screen and (max-width: 390px) {
  .main {
    display: flex;
    flex-direction: column;
  }
  .main-text {
    width: 100%;
  }
  .bio {
    font-size: 50px;
  }
  .main-image {
    margin-top: 20px;
    width: 100%;
    height: 400px;
    position: relative;
    display: flex;
  }
  .main-image1 {
    margin-top: 20px;
    width: 100%;
    height: 400px;
    position: relative;
    display: flex;
  }
  .txt {
    right: -5px;
    top: 10px;
  }
  .txt1 {
    margin-top: 15px;
    font-size: 30px;
  }
  .dataContainer {
    display: flex;
    flex-direction: column;
  }
  .card1 {
    width: 70%;
    margin: 0 auto;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
